<script setup>
import { onMounted, useTemplateRef, defineModel, defineProps } from 'vue';

const textarea = useTemplateRef('textarea-ref')
const props = defineProps(["class", "placeholder"])
const model = defineModel()

const resize = () => {
  textarea.value.style.height = 'auto';
  textarea.value.style.height = textarea.value.scrollHeight + 'px';
}

onMounted(() => {
  setTimeout(() => { resize() }, 200)
})
</script>
<template>
    <textarea 
        ref="textarea-ref" 
        :placeholder="props.placeholder"  
        v-model="model"
        v-on:keyup="resize" 
        :class="props.class"
    ></textarea>
</template>
<style scoped>
textarea {
    resize: none;
    padding: 8px 8px;
}
</style>