<script setup>
import { ref, defineModel } from 'vue'
import TooltipHover from '@/components/TooltipHover'
import ResizableTextarea from './ResizableTextarea.vue';

const charter = defineModel()

const verbs = ref(['Select Verb', 'Increase', 'Decrease', 'Maintain', 'Optimize', 'Improve'])

const addMeasure = () => {
  charter.value.measures.push({
    verb: 'Select Verb',
    description: '',
    source: ''
  })
}

const removeMeasure = (index) => {
  charter.value.measures.splice(index, 1)
}

</script>
<template>
    <section class="section measures-section">
      <div class="section-header">
        <TooltipHover tooltipContent="How will you (and others) know if this team is successful? What business outcomes is this team driving? Starting with a verb will ensure that you\’re not just describing a project that can be completed. For each, try to add a URL for the source of truth."/>
        <h3>Measures</h3>
      </div>
      <div class="section-content">
        <ul class="horizontal-list">
          <li v-for="(measure, index) in charter.measures" :key="index">
            <select v-model="measure.verb" class="measure-verb-select">
              <option v-for="verb in verbs" :key="verb" :value="verb">{{ verb }}</option>
            </select>
            <ResizableTextarea v-model="measure.description" class="measure-description-input" placeholder="Measure description" />
            <!-- <ResizableTextarea v-model="measure.source" class="measure-source-input" placeholder="Source" /> -->
            <button @click="removeMeasure(index)" class="remove-button">remove</button>
          </li>
        </ul>
        <button @click="addMeasure" class="add-button">+ Add measure</button>
      </div>
    </section>
</template>
<style scoped>
.measures-section .horizontal-list li {
  display: grid;
  grid-template-columns: 120px .9fr 40px;
  gap: 10px;
  margin-bottom: 16px;
  align-items: start;
}

.measure-verb-select {
  padding: 16px;
  border-radius: 0;
  font-size: 14px;
  color: #000;
  appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2214%22%20height%3D%2214%22%20viewBox%3D%220%200%2014%2014%22%3E%3Cpath%20fill%3D%22%23000%22%20d%3D%22M7%2010L0%203h14z%22%2F%3E%3C%2Fsvg%3E");
  border: none;
  background-repeat: no-repeat;
  background-position: right 8px center;
}

</style>