<script setup>
import { defineModel } from 'vue'
import TooltipHover from '@/components/TooltipHover'
import ResizableTextarea from './ResizableTextarea.vue';

const charter = defineModel()

const addRole = () => {
  charter.value.roles.push({
    name: '',
    description: '',
    member: ''
  })
}

const removeRole = (index) => {
  charter.value.roles.splice(index, 1)
}
</script>
<template>
    <section class="section roles-section">
      <div class="section-header">
        <TooltipHover tooltipContent="What roles are required to achieve the mission, to complete the focus areas, and deliver the outcomes? What do we expect of those roles? Who fills them?"/>
        <h3>Roles</h3>
      </div>
      <div class="section-content">
        <ul class="horizontal-list">
          <li v-for="(role, index) in charter.roles" :key="index">
            <ResizableTextarea v-model="role.name" class="role-name-input" placeholder="Name" />
            <ResizableTextarea v-model="role.description" class="role-focus-input" placeholder="Focus" />
            <ResizableTextarea v-model="role.member" class="role-filler-input" placeholder="Filled by" />
            <button @click="removeRole(index)" class="remove-button">Remove</button>
          </li>
        </ul>
        <button @click="addRole" class="add-button">+ Add role</button>
      </div>
    </section>

</template>
<style scoped>
.roles-section .horizontal-list li {
  display: grid;
  grid-template-columns: 200px 1fr 200px 80px;
  gap: 16px;
  margin-bottom: 16px;
}
</style>