<script setup>
import { defineModel } from 'vue'
import TooltipHover from '@/components/TooltipHover'
import ResizableTextarea from './ResizableTextarea.vue';

const charter = defineModel()

const decisionPermissions = ['Decides', 'Consults', 'Informs', 'Executes']

const addDecisionRight = () => {
  charter.value.decisionRights.push({
    right: '',
    permissions: null
  })
}

const updatePermissions = (right, permission) => {
  const perms = new Set(right.permissions || [])
  if (perms.has(permission)) { 
    perms.delete(permission) 
  } else {
    perms.add(permission)
  }
  right.permissions = [...perms]
}

const removeDecisionRight = (index) => {
  charter.value.decisionRights.splice(index, 1)
}

const hasRight = (right, permission) => {
  if (right.permissions == null) { right.permissions = [] }
  return right.permissions.includes(permission)
}

</script>
<template>
    <section class="section decision-rights-section">
      <div class="section-header">
        <TooltipHover tooltipContent="Warning: don’t try to do this for all the activities that the team might do. Only capture the most important decisions that this team will make or participate in."/>
        <h3>Decision Rights</h3>
      </div>
      <div class="section-content">
        <ul class="horizontal-list">
          <li v-for="(right, index) in charter.decisionRights" :key="index">
            <ResizableTextarea v-model="right.right" class="decision-right-input" placeholder="Decision Right" />
            <div class="decision-levels">
              <button 
                v-for="permission in decisionPermissions" 
                :key="permission"
                :class="['decision-level-button', { active: hasRight(right, permission) }]"
                @click="updatePermissions(right, permission)">
                {{ permission }}
              </button>
            </div>
            <button @click="removeDecisionRight(index)" class="remove-button">remove</button>
          </li>
        </ul>
        <button @click="addDecisionRight" class="add-button">+ Add Decision Right</button>
      </div>
    </section>

</template>
<style scoped>
li {
  display: flex;
  padding: 4px 0px;
}

li textarea {
  margin-right: 15px;
}

.decision-levels {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  max-height: 75px;
}

.decision-level-button {
  padding: 8px 16px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: white;
  font-size: 14px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.8);
  white-space: nowrap;
}

.decision-level-button.active {
  background-color: #22c55e;
  color: white;
  border-color: #22c55e;
}
</style>