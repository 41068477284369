<script setup>
import { ref, onMounted } from 'vue'
import TeamMeta from '@/components/TeamMeta'
import TeamMission from '@/components/TeamMission'
import FocusAreas from '@/components/FocusAreas'
import TeamMeasures from '@/components/TeamMeasures'
import DecisionRights from '@/components/DecisionRights'
import TeamRoles from '@/components/TeamRoles'

const API_URL = process.env.VUE_APP_API_URL

const charter = ref({
  name: '',
  mission: '',
  focusAreas: ['', '', ''], 
  measures: [{ verb: 'Select Verb', description: '', source: '' }],
  decisionRights: [{ right: '', permissions: ['Decides', 'Consults'] }],
  roles: [{ name: '', description: '', member: '' }]
})

const getCharterName = () => {
  const pathArr = window.location.pathname.split("/t/")
  if (pathArr.length > 1) {
    return pathArr[1]
  }
}

const loadCharter = async () => {
  const charterName = getCharterName()
  console.log(charterName)
  if (charterName == undefined) { return }

  try {
    const response = await fetch(`${API_URL}/api/charters/${charterName}`)
    charter.value = await response.json()
  } catch (error) {
    console.error('Error loading charter:', error)
  }
}

const saveCharter = async () => {
  try {
    const response = await fetch(`${API_URL}/api/charters`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(charter.value)
    })
    charter.value = await response.json()
    window.location = window.location.origin + "/t/" + charter.value.versionChain
  } catch (error) {
    console.error('Error saving charter:', error)
  }
}

onMounted(() => {
  loadCharter()
})
</script>

<template>
  <div class="container">
    <TeamMeta v-model="charter" />
    <TeamMission v-model="charter" />
    <FocusAreas v-model="charter" />
    <TeamMeasures v-model="charter" />
    <DecisionRights v-model="charter" />
    <TeamRoles v-model="charter" />
    <button @click="saveCharter" class="save-button" v-if="charter.name">Save Changes</button>
  </div>
</template>
<style>
/* Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Container */
.container {
  max-width: 1100px;
  margin: 0 auto;
  padding: 40px 20px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
  color: #333;
}

/* Common Input Styles */
input, textarea, select {
  width: 100%;
  padding:4px 5px 30px 5px;
  border: none;
  border-top: 1px solid #000;
  background-color: #f8f9fa;
  font-size: 16px;
  color: #333;
}

input::placeholder, textarea::placeholder {
  color: rgba(0, 0, 0, 0.4);
}

input.team-name::placeholder {
  color: #000;
}

.team-name {
  width: 100%;
  font-size: 28px;
  font-weight: bold;
  padding: 20px 16px;
  margin-bottom: 16px;
  color: #000;
}

/* Section Styles */
.section {
  display: flex;
  align-items: start;
  margin-bottom: 60px;
}

.section-header {
  display: flex;
  flex: 0 0 auto;
  width: 200px;
  align-items: start;
  margin-bottom: 24px;
}

.section-content {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.section-header h3 {
  font-size: 20px;
  font-weight: bold;
  color: #000;
}

/* Common Elements */
.add-button {
  padding: 8px 0;
  background: none;
  border: none;
  color: #000;
  max-width: 170px;
  font-size: 16px;
  cursor: pointer;
  text-align: left;
}

.remove-button {
  padding: 8px 16px;
  border: none;
  background: transparent;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.6);
  white-space: nowrap;
}

/* Save Button */
.save-button {
  position: fixed;
  bottom: 24px;
  right: 24px;
  padding: 12px 24px;
  background-color: #22c55e;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.save-button:hover {
  background-color: #16a34a;
}

/* List Styles */
.horizontal-list {
  list-style: none;
  padding: 0;
}

@media only screen and (max-width: 600px) {
  .section {
    flex-direction: column;
  }
}
</style>