<script setup>
import { defineModel } from 'vue'
import TooltipHover from '@/components/TooltipHover'
import ResizableTextarea from './ResizableTextarea.vue';
const charter = defineModel()

</script>
<template>
<section class="section mission-section">
    <div class="section-header">
      <TooltipHover tooltipContent="One or two sentences that answer questions like: What is the essential intent for the team? Why does the team exist? What is the one thing that our teammates care most about?"/>
      <h3>Team Mission</h3>
    </div>
    <ResizableTextarea v-model="charter.mission" class="mission-input" />
</section>
</template>
<style scoped>
.mission-input {
  flex: 1;
  min-height: 120px;
  resize: vertical;
  line-height: 1.5;
}
</style>