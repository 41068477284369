<script setup>
import { defineProps } from 'vue'

const props = defineProps({
    tooltipContent: {
      type: String,
      required: true
    }
})
</script>

<template>
  <div class="tooltip-container">
    <div class="question-mark">?</div>
    <div class="tooltip-content">
      {{ props.tooltipContent }}
    </div>
  </div>
</template>

<style lang="css" scoped>
.tooltip-container {
  position: relative;
  display: inline-block;
}

.question-mark {
  width: 24px;
  height: 24px;
  border: 1.5px solid rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.4);
  margin-right: 12px;
  cursor: help;
}

.tooltip-content {
  visibility: hidden;
  width: 300px;
  background-color: rgba(0, 0, 0, 1);
  color: #fff;
  text-align: left;
  padding: 12px;
  position: absolute;
  top: 25px;
  left: 50%;
  z-index: 10;
  font-size: 15px;
  line-height: 1.5;
  white-space: normal;
}

.tooltip-container:hover .tooltip-content {
  visibility: visible;
}
</style>
