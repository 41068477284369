<script setup>
import { defineModel, computed } from 'vue'
import TooltipHover from '@/components/TooltipHover'

const charter = defineModel()

const timeSince = (date) => {
  const inboundDate = new Date(date)
  const seconds = Math.floor((new Date() - inboundDate) / 1000);
  let interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years ago";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months ago";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days ago";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours ago";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes ago";
  }
  return Math.floor(seconds) + " seconds ago";
}

const charterURL = computed(() => `${window.location.origin}/t/${charter.value.versionChain}` )

</script>
<template>
<header class="header">
    <div class="header-title">
        <h1>Team Name</h1>
        <TooltipHover tooltipContent="Keep it memorable, short, and try not to change it too frequently."/>
    </div>
    <div class="team-meta">
        <input v-model="charter.name" class="team-name" placeholder="Team Name" />
        <span class="team-url" v-if="charter.versionChain"><a :href="charterURL">{{  charterURL }}</a></span>
        <span class="team-url" v-if="charter.updated_at">Last edited {{ timeSince(charter.updated_at) }}</span>
        <!-- <div class="team-actions">
            <a href="#">Share</a>
            <a href="#">See Previous Versions</a>
        </div> -->
    </div>
</header>
</template>
<style scoped>
.header {
  margin-bottom: 60px;
  display: flex;
  align-items: start;

}

.header-title {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

h1 {
  display: none;
}

.team-meta {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  font-size: 14px;
  color: #000;
  gap: 10px;
  flex: 1;
}

.team-url {
  color: rgba(0, 0, 0, 0.6);
  text-decoration: none;
}
</style>