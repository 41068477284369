<script setup>
import { defineModel } from 'vue'
import TooltipHover from '@/components/TooltipHover'
import ResizableTextarea from './ResizableTextarea.vue';

const charter = defineModel()

const addFocusArea = () => {
  charter.value.focusAreas.push('')
}

const moveFocusArea = (index, direction) => {
  if (direction === 'up' && index > 0) {
    [charter.value.focusAreas[index], charter.value.focusAreas[index - 1]] = 
    [charter.value.focusAreas[index - 1], charter.value.focusAreas[index]]
  } else if (direction === 'down' && index < charter.value.focusAreas.length - 1) {
    [charter.value.focusAreas[index], charter.value.focusAreas[index + 1]] = 
    [charter.value.focusAreas[index + 1], charter.value.focusAreas[index]]
  }
}

const removeFocusArea = (index) => {
  charter.value.focusAreas.splice(index, 1)
}

</script>
<template>
    <section class="section focus-areas-section">
      <div class="section-header">
        <TooltipHover tooltipContent="An ordered list (keep it short!) of the most important projects for the team. This list should be helpful for your teammates to prioritize their work, and for other teams to know what you’re working on."/>
        <h3>Focus Areas</h3>
      </div>
      <div class="section-content">
        <ul>
          <li v-for="(area, index) in charter.focusAreas" :key="index" class="focus-area-item">
            <span class="focus-area-number">{{ index + 1 }}</span>
            <ResizableTextarea v-model="charter.focusAreas[index]" class="focus-area-input" placeholder="Focus Area" />
            <button @click="moveFocusArea(index, 'down')" class="move-button" :class="{ disabled: index === charter.focusAreas.length - 1 }">↓</button>
            <button @click="moveFocusArea(index, 'up')" class="move-button" :class="{ disabled: index === 0 }">↑</button>
            <button @click="removeFocusArea(index)" class="remove-button">remove</button>
          </li>
        </ul>
        <button @click="addFocusArea" class="add-button">+ Add Focus Area</button>
      </div>
    </section>
</template>
<style scoped>
.focus-area-item {
  display: grid;
  grid-template-columns: 30px 1fr 40px 40px 65px;
}

.focus-area-number {
  font-size: 16px;
  color: #000;
}

.move-button {
  padding: 8px;
  border: none;
  background: transparent;
  cursor: pointer;
  color: #000;
  font-weight: bold;
  font-size: 16px;
}

.move-button.disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

</style>